<template>
    <div>
        <Breadcrumb></Breadcrumb>
        <div class="header">
            <el-row>
                <div class="form">
                    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                        <el-form-item label="权限名">
                            <el-input v-model="searchForm.name" placeholder="权限名" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="是否显示">
                            <el-select v-model="searchForm.is_show" clearable placeholder="是否显示" size="small">
                                <el-option label="是" value="1"></el-option>
                                <el-option label="否" value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否菜单">
                            <el-select v-model="searchForm.is_menu" clearable placeholder="是否菜单" size="small">
                                <el-option label="是" value="1"></el-option>
                                <el-option label="否" value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="启用状态">
                            <el-select v-model="searchForm.status" clearable placeholder="启用状态" size="small">
                                <el-option label="启用" value="1"></el-option>
                                <el-option label="禁用" value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" icon="el-icon-search" @click="getAuthList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-button type="danger" size="small" @click="delAuth()">批量删除</el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" size="small" @click="showForm('add')">
                        <i class="el-icon-circle-plus-outline"></i>
                        添加
                    </el-button>
                </el-col>
            </el-row>
        </div>
        <div class="content">
            <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" :default-sort="{ prop: 'id', order: 'ascending' }">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="权限名"></el-table-column>
                <el-table-column prop="url" label="权限url"></el-table-column>
                <el-table-column prop="pid" label="上级" :formatter="pidFormatter"></el-table-column>
                <el-table-column prop="icon" label="图标"></el-table-column>
                <el-table-column prop="sort" label="排序" sortable></el-table-column>
                <el-table-column prop="is_show" label="是否显示" :formatter="isShowFormatter"></el-table-column>
                <el-table-column prop="is_menu" label="是否菜单" :formatter="isMenuFormatter"></el-table-column>
                <el-table-column prop="status" label="启用状态" :formatter="statusFormatter"></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="showForm('edit', scope.row.id)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="delAuth(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <Pagination :total="total" :perPage="perPage" :currentPage="currentPage" @currentPageChange="currentPageChange"></Pagination>
            </div>
        </div>

        <AddForm v-if="addFormDialog" :addFormDialog="addFormDialog" @closeForm="closeForm" @getAuthList="getAuthList"></AddForm>
        <EditForm v-if="editFormDialog" :editFormDialog="editFormDialog" :editFormId="editFormId" @closeForm="closeForm" @getAuthList="getAuthList"></EditForm>
    </div>
</template>

<script>
import { getAuthList, getCheckAuth, delAuth } from '@/utils/servers/adminAuth.js';
import { getCheckRole } from '@/utils/servers/adminRole.js';
import Pagination from '@/components/Pagination/Pagination.vue';
import AddForm from './add.vue';
import EditForm from './edit.vue';
export default {
    components: {
        Pagination,
        AddForm,
        EditForm
    },
    computed: {
        pidFormatter() {
            return (row, column, value) => {
                let arr = this.checkAuth.find(val => val.id === value);
                if (arr) {
                    return arr.name;
                } else if (value === 0) {
                    return '顶级url';
                } else {
                    return value;
                }
            };
        },
        isShowFormatter() {
            return (row, column, value) => {
                let arr = [
                    { key: 0, name: '隐藏' },
                    { key: 1, name: '显示' }
                ];
                let item = arr.find(item => item.key === value);
                if (item) {
                    return item.name;
                } else {
                    return value;
                }
            };
        },
        isMenuFormatter() {
            return (row, column, value) => {
                let arr = [
                    { key: 0, name: '否' },
                    { key: 1, name: '是' }
                ];
                let item = arr.find(item => item.key === value);
                if (item) {
                    return item.name;
                } else {
                    return value;
                }
            };
        },
        statusFormatter() {
            return (row, column, value) => {
                let arr = [
                    { key: 0, name: '禁用' },
                    { key: 1, name: '启用' }
                ];
                let item = arr.find(item => item.key === value);
                if (item) {
                    return item.name;
                } else {
                    return value;
                }
            };
        }
    },
    data() {
        return {
            searchForm: {
                name: '',
                is_show: '',
                is_menu: '',
                status: ''
            },
            list: [],
            total: 0,
            perPage: 0,
            currentPage: 0,
            checkIds: [],
            checkRole: [],
            checkAuth: [],
            addFormDialog: false,
            editFormDialog: false,
            editFormId: 0
        };
    },
    created() {
        this.getCheckRole();
        this.getCheckAuth();
        this.getAuthList();
    },
    methods: {
        handleSelectionChange(val) {
            this.checkIds = val ? val.map(item => item.id) : [];
        },
        currentPageChange(val) {
            this.currentPage = val;
            this.getAuthList();
        },
        getAuthList() {
            getAuthList({ searchForm: this.searchForm, perPage: this.perPage, currentPage: this.currentPage }).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.list = data.data.data;
                    this.total = data.data.total;
                    this.perPage = data.data.per_page;
                    this.currentPage = data.data.current_page;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckRole() {
            getCheckRole().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkRole = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckAuth() {
            getCheckAuth().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkAuth = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        delAuth(id) {
            this.$confirm('此操作将永久删除数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids = id ? id : this.checkIds.toString();
                delAuth({ ids: ids }).then(res => {
                    var data = res;
                    if (data.code == 1) {
                        this.$message.success(data.msg);

                        if (this.total % this.perPage === 1) {
                            this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1;
                        }

                        this.getAuthList();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        showForm(name, id = 0) {
            if (name == 'add') {
                this.addFormDialog = true;
            } else if (name == 'edit') {
                this.editFormDialog = true;
                this.editFormId = id;
            }
        },
        closeForm(name) {
            if (name == 'add') {
                this.addFormDialog = false;
            } else if (name == 'edit') {
                this.editFormDialog = false;
            }
        }
    }
};
</script>

<style lang="less" scoped>
.header {
    background: #fff;
    margin-bottom: 20px;
}
.content {
    .pagination {
        padding: 10px;
    }
}
</style>
